<template>
  <div class="meditation-item" :class="{ 'meditation-item--focus': focus}" @click="onClick">
    <PlayIcon :isPlaying="isPlaying" v-if="!focus && posterLoaded" />
    <video muted playsinline ref="video" :loop="focus" :autoplay="focus">
      <source :src="video" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    <img class="poster" :src="poster" :alt="poster" @load="onPosterLoad" v-if="!focus">
  </div>
</template>

<script>
import PlayIcon from "../../icons/PlayIcon.vue";

export default {
  name: "MeditationItem",
  mixins: [],
  components: { PlayIcon },
  props: {
    video: {
      default: undefined
    },
    poster: {
      default: undefined
    },
    item: {
      default: undefined
    },
    focus: {
      default: false
    }
  },

  data() {
    return {
      isPlaying: false,
      posterLoaded: false
    };
  },

  methods: {
    onPosterLoad() {
      this.posterLoaded = true;
      this.$emit('loaded');
    },
    onClick() {
      if (this.focus) this.isPlaying = !this.isPlaying;
    }
  },
  watch: {
    isPlaying() {

    },
    item() {
      this.isPlaying = false;
    },
  },
  computed: {},
  beforeDestroy() { },

  mounted() {

  }
};
</script>
