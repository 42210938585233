<template>
  <div class="play-icon">
    <inline-svg ref="svg" :src="require('@/assets/svg/icon_play.svg')" />
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "PlayIcon",
  mixins: [],
  components: {},
  props: {
      isPlaying:undefined
  },

  data() {
    return {};
  },

  methods: {},
  watch: {
      isPlaying(){
          if(this.isPlaying) {
              gsap.to(this.$el,{ease:"Expo.easeOut",scale:1.5,autoAlpha:0,duration:1});
          }else{
              gsap.to(this.$el,{scale:1,autoAlpha:1,duration:.25});
          }
      }
  },
  computed: {},
  beforeDestroy() {},

  mounted() {
    gsap;
  }
};
</script>

<style scoped lang="scss"></style>
