<template>
  <div class="meditation-interaction">
    <div class="meditation-interaction__container">
      <h2 ref="h1">Are you ready to relax?</h2>
      <h3 ref="h2">Go ahead and pick what fits for you!</h3>

      <div
        class="meditation-interaction__items"
        ref="container"
        v-if="focusItem === undefined"
      >
        <MeditationItem
          key="item1"
          ref="item_1"
          video="assets/videos/meditation_2.mp4"
          :poster="'assets/videos/meditation_2.jpg'"
          @click.native="onItemClick(2)"
        />
        <MeditationItem
          key="item2"
          ref="item_2"
          video="assets/videos/meditation_3.mp4"
          :poster="'assets/videos/meditation_3.jpg'"
          @click.native="onItemClick(3)"
        />
        <MeditationItem
          key="item3"
          ref="item_3"
          video="assets/videos/meditation_1.mp4"
          :poster="'assets/videos/meditation_1.jpg'"
          @click.native="onItemClick(1)"
        />
      </div>

      <div class="meditation-interaction__focus loaded"></div>
      <!--no one knows...-->
      <div class="meditation-interaction__focus" :class="{'loaded':focusItemLoaded}"  v-if="focusItem !== undefined">
        <MeditationItem
          :key="'itemfocus'+focusItem"
          ref="focus_item"
          :focus="true"
          :item="focusItem"
          :video="focusItemVideo"
          :poster="focusItemPoster"
          @loaded="onFocusItemLoaded"
        />

        <div class="meditation-interaction__focus-bg" ref="focus_bg"></div>

        <inline-svg
          @click="onLeftClick"
          ref="page_left"
          class="meditation-interaction__page-left"
          :src="require('@/assets/svg/swiper_pager.svg')"
        />
        <inline-svg
          ref="page_right"
          @click="onRightClick"
          class="meditation-interaction__page-right"
          :src="require('@/assets/svg/swiper_pager.svg')"
        />
      </div>

      <Button
        class="button--small"
        @click.native="backToOverview"
        v-if="focusItem"
        >BACK TO OVERVIEW</Button
      >
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import DownloadHelperMixin from "../../../mixins/DownloadHelperMixin";
import Button from "../../../components/form/Button";
import MeditationItem from "./MeditationItem.vue";

export default {
  name: "MeditationInteraction",
  mixins: [DownloadHelperMixin],
  components: { Button, MeditationItem },
  data() {
    return {
      focusItemIndex: undefined,
      focusItem: undefined,
      focusItemVideo: undefined,
      focusItemPoster: undefined,
      focusItemLoaded:false,
      videos:["assets/videos/meditation_1.mp4","assets/videos/meditation_2.mp4","assets/videos/meditation_3.mp4"]
    };
  },
  methods: {
    onRightClick() {
      let next = this.focusItem + 1;
      if (next > 3) next = 1;
      this.focusItemIndex = next;

    },
    onLeftClick() {
      let next = this.focusItem - 1;
      if (next === 0) next = 3;
      this.focusItemIndex = next;
    },
    backToOverview() {
      gsap.to(this.$refs.page_left.$el, { autoAlpha: 0, duration: 0.25 });
      gsap.to(this.$refs.page_right.$el, { autoAlpha: 0, duration: 0.25 });

      gsap.to(this.$refs.focus_item.$el, { duration: 0.5, autoAlpha: 0 });
      gsap.to(this.$refs.focus_item.$el, {
        ease: "Expo.easeOut",
        scale: 1,
        duration: 0.5
      });
      gsap
        .to(this.$refs.focus_bg, {
          scaleY: 0,
          autoAlpha: 0,
          duration: 0.5,
          overwrite: true
        })
        .then(() => {
          this.focusItem = undefined;         
          this.focusItemVideo = undefined;
          this.focusItemIndex = undefined;
        });
    },
    onItemClick(id) {
      gsap.to(this.$refs.h1, { autoAlpha: 0 });
      gsap.to(this.$refs.h2, { autoAlpha: 0 });

      gsap
        .to(this.$refs.container, {
          ease: "Expo.easeOut",
          scale: 1,
          autoAlpha: 0
        })
        .then(() => {
          this.focusItem = id;
        });
    },
    onResize() {},
    init() {
      this.onResize();
    },
    onFocusItemLoaded(){
      this.focusItemLoaded = true;
    }
  },
  watch: {
    focusItemVideo(){
      this.focusItemLoaded = false;

if(this.focusItemVideo) {
        this.focusItemPoster = this.focusItemVideo.replace('.mp4','.jpg');
      }else{
        this.focusItemPoster = this.focusItemVideo;
      }

      
    },
    focusItemIndex(){
       
       if(this.focusItemIndex !== undefined) {
         
         gsap
              .to(this.$refs.focus_item.$el, {
                autoAlpha: 0,
                scale: 1.1,
                duration: 0.25
              })
              .then(() => {
                this.focusItem = this.focusItemIndex
                this.focusItemVideo = this.videos[this.focusItemIndex-1];

                gsap.set(this.$refs.focus_item.$el, {
                  opacity: 0,
                  scale: 0.8,
                  overwrite: true
                });
                
              });
          
                  }
        

    },
    focusItem(prev,now) {
      this.$nextTick(() => {
        if (this.focusItem) {
         
            this.focusItemVideo = this.videos[prev-1];
            gsap.set(this.$refs.focus_item.$el, { autoAlpha: 0, scale: 0.9 });

              if(!now) {

                gsap.set(this.$refs.focus_bg, {
                  scaleY: 0,
              autoAlpha: 0
            });
              }

            gsap.to(this.$refs.focus_item.$el, { delay: 0.4, autoAlpha: 1 });
            gsap.to(this.$refs.focus_item.$el, {
              ease: "Expo.easeOut",
              scale: 1,
              duration: 4,
              delay: 0.4
            });

            gsap.to(this.$refs.focus_bg, {
              ease: "Expo.easeOut",
              scaleY: 1,
              autoAlpha: 1,
              duration: 2
            });
          
        } else {
          //overview
          gsap.to(this.$refs.h1, { autoAlpha: 1 });
          gsap.to(this.$refs.h2, { autoAlpha: 1 });

          gsap.to(this.$refs.container, {
            ease: "Expo.easeOut",
            scale: 1,
            autoAlpha: 1,
            duration: 0
          });

          gsap.set(this.$refs.container.children, {
            scale: 0.9,
            autoAlpha: 0
          });

          gsap.to(this.$refs.container.children, {
            duration: 2,
            scale: 1,
            autoAlpha: 1,
            ease: "Expo.easeOut",
            stagger: {
              each: 0.05,
              from: "center"
            }
          });
        }
      });
    },
    stageWidth() {
      this.onResize();
    }
  },
  computed: {
    stageWidth() {
      return this.$screen.width;
    }
  },
  beforeDestroy() {},

  mounted() {
    gsap.set(this.$el, {
      scale: 1,
      alpha: 0
    });

    gsap.to(this.$el, {
      ease: "Expo.easeOut",
      duration: 2,
      scale: 1,
      alpha: 1
    });
  }
};
</script>
